<template>
  <div class="reservation-cancel-complete">
    <div class="page-title">
      <base-page-title>{{
        $t('reservationCancelComplete.pageTitle')
      }}</base-page-title>
    </div>
    <div class="check-mark"><base-check-mark /></div>
    <div class="message">
      <div class="message-p1">
        {{ $t('reservationCancelComplete.message') }}
      </div>
      <div class="reservation-info">
        <div class="patients-names">
          <div
            class="patient-name"
            data-test="patient-name"
            v-for="(patientName, index) in cancelReservation.patientsNames"
            :key="index"
          >
            {{ displayName(patientName) }}
          </div>
        </div>
        <div class="reservation-date-time" data-test="reservation-date-time">
          {{ dispDate }}<br class="br-time" />{{ dispTime }}
        </div>
      </div>
    </div>
    <div class="menu-button">
      <base-decision-button @click="pushToReservationMenu">{{
        $t('common.buttonMenu')
      }}</base-decision-button>
    </div>
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import BaseCheckMark from '@/components/parts/atoms/BaseCheckMark.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import PreventBack from '@/components/mixins/PreventBack'
import moment from 'moment'
moment.locale('ja')

export default {
  name: 'ReservationCancelComplete',

  components: {
    BasePageTitle,
    BaseCheckMark,
    BaseDecisionButton
  },

  mixins: [PreventBack],

  computed: {
    cancelReservation() {
      return this.$store.getters['reservation/getCancelReservation']
    },
    dispDate() {
      if (this.$i18n.locale === 'en') {
        moment.locale('en')
        return moment(this.cancelReservation.date, 'YYYYMMDD').format(
          'dddd, MMMM D, YYYY, '
        )
      } else {
        moment.locale('ja')
        return moment(this.cancelReservation.date, 'YYYYMMDD').format(
          'YYYY年MM月DD日（dd）'
        )
      }
    },
    dispTime() {
      if (this.$i18n.locale === 'en') {
        moment.locale('en')
        return moment(this.cancelReservation.startTime, 'HHmm').format(
          '[from] h:mm a'
        )
      } else {
        moment.locale('ja')
        return moment(this.cancelReservation.startTime, 'HHmm').format(
          'HH時mm分[～]'
        )
      }
    }
  },

  beforeDestroy() {
    if (this.$route.path === '/reservation-confirm') {
      this.$router.push({ name: 'ReservationCancelComplete' })
    }
  },

  methods: {
    pushToReservationMenu() {
      this.$store.dispatch('reservation/resetCancelReservation')
      this.$router.push({ name: 'ReservationMenu' })
    },
    displayName(name) {
      return this.$i18n.locale === 'ja' ? name : name.slice(0, -1)
    }
  }
}
</script>

<style lang="scss">
.reservation-cancel-complete {
  > .page-title {
    margin-top: 70px;
  }
  > .check-mark {
    margin: 105px 0 47px;
  }
  > .message {
    font-weight: bold;
    text-align: center;
    > .message-p1 {
      margin-bottom: 36px;
    }
    > .reservation-info {
      display: flex;
      justify-content: center;
      > .patients-names {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      > .reservation-date-time {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 300px;
        margin-left: 25px;
        text-align: center;
      }
    }
  }
  > .menu-button {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
@media (min-width: 640px) {
  .reservation-cancel-complete {
    .message {
      .reservation-info {
        > .reservation-date-time {
          > .br-time {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 640px) {
  .reservation-cancel-complete {
    .message {
      .reservation-info {
        > .reservation-date-time {
          min-width: 195px;
        }
      }
    }
  }
}
</style>
